import React,{ useRef } from 'react'
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EmailSignUp = () => {
    const formEl = useRef(null);
    const doHtmlFormPost = values => {
      formEl.current.submit();
    };
      const formik = useFormik({
          initialValues: {
            FIRSTNAME:'',
            // LASTNAME:'',
            EMAIL: '',
          },
          validationSchema: Yup.object({
            FIRSTNAME: Yup
            .string()
            .required('Required'),
            // LASTNAME: Yup
            // .string()
            // .required('Required'),
            EMAIL: Yup
            .string()
            .email()
            .matches('^[a-z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|live\.com)(?:[a-z0-9.-]+\.?)+$','Please enter company business email address. Example: name@yourwebsite.com')
            .required('Required')
          }),
          onSubmit: values => {
            console.log(values)
            fetch(
              `https://hooks.slack.com/services/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`,
              {
                method: "POST",
                body: JSON.stringify({
                  text: `🧱New Lead \nEmail: ${values.EMAIL}\nFirst Name: ${values.FIRSTNAME}🧱`,
                }),
              }
            )
            .then(()=>{
              doHtmlFormPost()
            })
            .catch((e) => { 
              console.log(e)
              doHtmlFormPost()
            });
           
          },
        });
  return (
    <form className='growthChecklist-ctaEmailContainer'method="POST" onSubmit={formik.handleSubmit} ref={formEl} action='https://c375da00.sibforms.com/serve/MUIEAH0ikRfQkKxGMjN8_6Z8J3DsODjzWYxYrEL91Wf8j456tRhs-4GzXeql004Bgyc_jbYr6_Lf2oKDDJ_mSiUVs4Kvoy0WFj_h27BwgfbPH_2r7plyECgok2C3FVf1Ua3gQPNY9WssNcj65uOCR3ZMtTok23vSdBonfaNE_w7XJS2-KntUA_WhELIVP6MSN0UaoYnoCsg3-dGc'>
      <div style={{width:'100%'}}>
        <input
          className='growthChecklist-ctaInput'
          type="text"
          id='FIRSTNAME'
          name='FIRSTNAME'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.FIRSTNAME}
          placeholder="Enter your first name"

        />
        {formik.touched.FIRSTNAME && formik.errors.FIRSTNAME ? (
          <div style={{color:'red'}}>{formik.errors.FIRSTNAME}</div>
        ) : null}
      </div>
      {/* <div style={{width:'100%'}}>
          <input
            className='growthChecklist-ctaInput'
            type="text"
            id='LASTNAME'
            name='LASTNAME'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.LASTNAME}
            placeholder="Enter your last name"
        />
        {formik.touched.LASTNAME && formik.errors.LASTNAME ? (
          <div>{formik.errors.LASTNAME}</div>
        ) : null}
      </div> */}
      <div style={{width:'100%'}}>
        <input
          className='growthChecklist-ctaInput'
          type="email"
          id='EMAIL'
          name='EMAIL'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.EMAIL}
          placeholder="Enter your work email"
        />
        {formik.touched.EMAIL && formik.errors.EMAIL ? (
          <div style={{color:'red'}}>{formik.errors.EMAIL}</div>
        ) : null}
        </div>
      <button className='growthChecklist-ctaButton' type="submit" >
        Download Now
      </button>
  </form>
  )
}

export default EmailSignUp