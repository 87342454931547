import React,{ useRef } from 'react'

import Layout from '../../../components/layout'
import Img from "gatsby-image"
import Logo from "../../../images/logo-04.png";
import "../../merchants.css";
import './growth-checklist.css'
import EmailSignUp from '../../../components/emailSignUp/EmailSignUp';

//lm meaning lead magnet
const GrowthChecklist = ({data}) => {
  const checklistImg = data.checklistImg.childImageSharp.fixed
  console.log(checklistImg)
  return (
    <Layout>
             <div className="merchants-header_container"style={{padding:'1rem 0'}}>
            <div className="merchants-header_textContainer">
                <div style={{display:'flex', marginBottom:'0.5rem'}}>
                    <img src={Logo} alt="logo"  style={{width:'3rem', margin:'0', cursor:'pointer'}} />
                    <h1 className="navbar-logo_text" style={{fontSize:'3rem'}}>Sizify</h1>
                </div>
        
            </div>

        </div>
    
      <div className="merchants-header_container">
        <div className="merchants-header_textContainer">
          <h2 className="merchants-header_title">
            {" "}
         Apparel Merchant<br></br>
         Growth Checklist
            
          </h2>

          <h3 className="merchants-header_subTitle">
          A free growth checklist for Shopify apparel merchants.
             </h3>
             <div className='growthChecklist-callToActionContainer'>
             <div className="growthChecklistTY-list-container">
           <div className="growthChecklistTY-list-section">
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            <h3 className='growthChecklistTY-list-text'>
                  Get more sales while saving on return costs
            </h3>
           </div>
           <div className="growthChecklistTY-list-section">
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            <h3 className='growthChecklistTY-list-text'>
                Learn tactics used by top 100 online apparel stores 
            </h3>
           </div>
           <div className="growthChecklistTY-list-section">
            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 24 24" aria-labelledby="okIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="okIconTitle">Ok</title> <polyline points="4 13 9 18 20 7"/> </svg> 
            <h3 className='growthChecklistTY-list-text'>
              Grow LTV used clothing store specific techniques
              </h3>
           </div>
         </div>
              <EmailSignUp/>
            </div>
            
         
        </div>

        <div className="growthChecklist-hero_imageContainer" >
              <Img fixed={checklistImg}/>
        </div>
      </div>
    </Layout>
   
  )
}

export default GrowthChecklist

export const query = graphql`
query {
  checklistImg: file(relativePath: { eq: "growth-bookcover-3.png" }) {
      childImageSharp {
        fixed(quality:100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`